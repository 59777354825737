<template>
  <div class="document-tags">
    <v-row>
      <v-col>
        <v-autocomplete
          label="Выбрать тег"
          dense
          outlined
          @input="onInputDefaultTag"
          v-model="defaultTag"
          :items="defaultTags"
          item-text="label"
          item-value="tag"
          hide-details
        />
      </v-col>
      <v-col cols="1" class="text-center">или</v-col>
      <v-col>
        <v-text-field
          label="Добавить новый"
          v-model="tag"
          dense
          hide-details
          outlined
          @keydown.enter="add"
        />
      </v-col>
      <v-col>
        <v-btn @click="add" :disabled="!tag">Добавить</v-btn>
      </v-col>
    </v-row>
    <v-chip-group>
      <v-chip
        close
        @click:close="remove(idx)"
        v-for="(item, idx) in innerValue"
        :key="item"
      >
        {{ item }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import documentsService from '@/services/documents'

export default {
  model: {
    prop: 'tags',
    event: 'input'
  },
  props: {
    tags: {
      type: Array
    }
  },
  data () {
    return {
      tag: null,
      innerValue: [],
      defaultTags: documentsService.tags,
      defaultTag: null
    }
  },
  watch: {
    tags: {
      immediate: true,
      handler (value) {
        this.innerValue = value || []
      }
    },
    innerValue (innerValue) {
      this.$emit('input', innerValue)
    }
  },
  methods: {
    remove (idx) {
      this.$delete(this.innerValue, idx)
    },
    add () {
      if (!this.tag) return
      this.innerValue.push(this.tag)
      this.tag = null
    },
    onInputDefaultTag (tag) {
      this.$nextTick(() => {
        this.defaultTag = null
        if (!this.innerValue.includes(tag)) {
          this.innerValue.push(tag)
        }
      })
    }
  }
}
</script>
